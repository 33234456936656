import * as React from "react"
import { Link } from "gatsby"

import "../styles/header.css"

const Header = ({restaurantUrl, toHome}) => {
  return (
    <div className="header">
      <div className="headerBox" id="headerLeft">
        <Link className="headerLink" to={toHome ? "/" : "/" + restaurantUrl}>Home</Link>
        <Link className="headerLink desktop" to={"/" + restaurantUrl + "-menu"}>Menu</Link>
      </div>
      <Link to="/" id="headerTitle">Vegang</Link>
      <div className="headerBox" id="headerRight">
          <Link className="headerLink mobile flex" to={"/" + restaurantUrl + "-menu"}>Menu</Link>
      </div>
    </div>
  )
}

export default Header