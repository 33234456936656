import * as React from "react"
import { Link } from "gatsby"

import "../styles/footer.css"

const Footer = ({restaurantMeta, legalOnly}) => {
    if (legalOnly) {
        return(
            <div className="footer">            
                <div className="footerWrapper">
                <div>
                    <p className="footerLabel">Legal</p>
                    <Link to= "/impressum" className="footerContent">Impressum</Link>
                    {/* <Link to= "/impressum#datenschutz" className="footerContent">Datenschutz</Link> */}
                </div>
                </div>
            </div>
        )
    }
  return (
      <div className="footer">            
          <div className="footerWrapper">
            <div>
            <p className="footerLabel">{restaurantMeta.name}</p>
            <p className="footerContent">{restaurantMeta.address}</p>
                <p className="footerContent">{restaurantMeta.plz} Berlin</p>
                </div>
                <div>
                <p className="footerLabel">Opening hours:</p>
                <p className="footerContent">{restaurantMeta.hours1}</p>
                <p className="footerContent">{restaurantMeta.hours2}</p>
                </div>
                <div>
                    <p className="footerLabel">Contact</p>
                    <a href={"tel:+"+restaurantMeta.phone} className="footerContent">Tel: {restaurantMeta.phone}</a>
                </div>
                <div>
                    <p className="footerLabel">Legal</p>
                    <Link to= "/impressum" className="footerContent">Impressum</Link>
                    {/* <Link to= "/impressum#datenschutz" className="footerContent">Datenschutz</Link> */}
                </div>
          </div>
          
     </div>
  )
}

export default Footer