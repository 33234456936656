import React from "react"
import TopBar from "../components/topBar"
import Header from "../components/header"
import Footer from "../components/footer"

import "../styles/global.css"
const rOBJ = require('../cb.json');

export default function Layout({ children }) {

  if (children.props.location.pathname === '/') {
    return (
      <main>
        {children}
        <Footer legalOnly="true"/>
      </main>
    )

  }

  if (children.props.location.pathname.includes('vegang-charlottenburg')) {
    return (
      <main>
        <TopBar restaurantMeta={rOBJ.cburgMeta}/>
        <Header toHome={children.props.location.pathname === '/vegang-charlottenburg/'} restaurantUrl="vegang-charlottenburg"/>
        {children}
        <Footer restaurantMeta={rOBJ.cburgMeta}/>
      </main>
    )
  }

  if (children.props.location.pathname.includes('vegang-prenzlauer-berg')) {
    return (
      <main>
        <TopBar restaurantMeta={rOBJ.pbergMeta}/>
        <Header toHome={children.props.location.pathname === '/vegang-prenzlauer-berg/'} restaurantUrl="vegang-prenzlauer-berg"/>
        {children}
        <Footer restaurantMeta={rOBJ.pbergMeta}/>
    </main>
    )
  }

  if (children.props.location.pathname === '/impressum/') {
    return(
      <main>
        <TopBar restaurantMeta={rOBJ.pbergMeta}/>
        <Header toHome={children.props.location.pathname === '/vegang-prenzlauer-berg/'} restaurantUrl="vegang-prenzlauer-berg"/>
        {children}
        <Footer restaurantMeta={rOBJ.pbergMeta}/>
      </main>
    )

  }


  return (
    <main>
     <h3>404</h3>
    </main>
  )
}