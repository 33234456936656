import * as React from "react"
import { MdLocationOn, MdPhoneIphone } from 'react-icons/md';
import { BiLogoInstagram, BiLogoFacebook } from 'react-icons/bi';

import "../styles/topBar.css"

const TopBar = ({restaurantMeta}) => {
  return (
    <div className="topBar">
      <a className="topBarLink" target="_blank" rel="noopener noreferrer" href={restaurantMeta.gLink}><MdLocationOn className="topBarIcon"/>{restaurantMeta.address}, {restaurantMeta.plz} Berlin</a>
      <a className="topBarLink" href={"tel:+" + restaurantMeta.phone}><MdPhoneIphone className="topBarIcon"/>{restaurantMeta.phone}</a>
      <a className="topBarLink" target="_blank" rel="noopener noreferrer" href={'https://www.facebook.com/' + restaurantMeta.facebook}><BiLogoFacebook className="topBarIcon"/>{restaurantMeta.facebook}</a>
      <a className="topBarLink" target="_blank" rel="noopener noreferrer" href={'https://www.instagram.com/' + restaurantMeta.instagram}><BiLogoInstagram className="topBarIcon"/>{restaurantMeta.instagram}</a>
    </div>
  )
}

export default TopBar