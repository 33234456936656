exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vegang-charlottenburg-js": () => import("./../../../src/pages/vegang-charlottenburg.js" /* webpackChunkName: "component---src-pages-vegang-charlottenburg-js" */),
  "component---src-pages-vegang-charlottenburg-menu-js": () => import("./../../../src/pages/vegang-charlottenburg-menu.js" /* webpackChunkName: "component---src-pages-vegang-charlottenburg-menu-js" */),
  "component---src-pages-vegang-prenzlauer-berg-js": () => import("./../../../src/pages/vegang-prenzlauer-berg.js" /* webpackChunkName: "component---src-pages-vegang-prenzlauer-berg-js" */),
  "component---src-pages-vegang-prenzlauer-berg-menu-js": () => import("./../../../src/pages/vegang-prenzlauer-berg-menu.js" /* webpackChunkName: "component---src-pages-vegang-prenzlauer-berg-menu-js" */)
}

